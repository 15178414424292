import { CardActionNode } from 'apollo/main/generated';

export enum Status {
    PROCESSING,
    CREATED,
    PAID,
    MONEY_RECEIVED,
    FAILED
}

export interface StatusProps {
    onClick?: () => void;
}

export interface VirtualCardStatusProps {
    action?: CardActionNode | null;
    onClose?: () => void;
}
